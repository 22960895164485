export default {
  purchasingResources: {
    common: [],
    feature: 'Purchasing.PurchaseDialog'
  },
  itemResources: {
    common: [],
    feature: 'Feature.Item'
  },
  itemModelResources: {
    common: [],
    feature: 'Feature.ItemModel'
  }
};
